<template>
  <div
    :class="[
      `cs-container cs-container-${size}`,
      paddingTop && 'pad-top',
      paddingBottom && 'pad-bottom',
      verticalPadding && 'pad-vertical',
      horizontalPadding && 'pad-horizontal',
      spaceBefore && 'space-before',
      spaceAfter && 'space-after',
    ]"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: "Container",
    props: {
      size: {
        type: String,
        default: "large",
        validator: (value) => {
          // The value must match one of these strings
          return ["large", "medium", "small", "narrow"].includes(value)
        },
      },
      paddingTop: {
        type: Boolean,
        default: true,
      },
      paddingBottom: {
        type: Boolean,
        default: true,
      },
      verticalPadding: {
        type: Boolean,
        default: true,
      },
      horizontalPadding: {
        type: Boolean,
        default: true,
      },
      spaceBefore: {
        type: Boolean,
        default: false,
      },
      spaceAfter: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cs-container {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: $content-max-width;

    &.pad-vertical {
      &.pad-top {
        margin-top: $gutter;
        &.space-before {
          margin-top: $gutter-double;
          .columns & {
            margin-top: $gutter;
          }
        }
      }
      &.pad-bottom {
        margin-bottom: $gutter;

        &.space-after {
          margin-bottom: $gutter-double;
          // .columns & {
          //   margin-bottom: $gutter;
          // }
        }
      }
    }
    &.pad-horizontal {
      padding-left: $gutter;
      padding-right: $gutter;
    }
    &-medium {
      max-width: $content-width-medium;
    }
    &-small {
      max-width: $content-width-small;
    }
    &-narrow {
      max-width: $content-width-narrow;
    }
    @media print {
      padding: $space-s !important;
      padding-top: 0 !important;
      margin-bottom: $space-s;
    }
  }

</style>
