<template>
  <Container>
    <Headline class="error-headline">
      <span v-if="!(isNetworkError || isTimeoutError)">
        {{ error.statusCode }}
      </span>

      {{ error.statusMessage }}
    </Headline>
    <p v-if="error.statusCode == 404" class="error-subline">
      We couldn't find the Page you we're looking for. Please check the URL or
      click the back button.
    </p>
    <div v-else-if="isNetworkError" class="support-block">
      <Paragraph size="large" class="support-block__paragraph">
        Oops! We lost connection to our servers while doing a little server
        maintenance. Please try again later. If you need help please go to
        <a href="https://support.candlescience.com">
          support.candlescience.com
        </a>
      </Paragraph>
    </div>
    <div v-else-if="isTimeoutError" class="support-block">
      <Paragraph size="large" class="support-block__paragraph">
        Oops! It took too long to get a response from our servers. Please try
        again. If you need help please go to
        <a href="https://support.candlescience.com">
          support.candlescience.com
        </a>
      </Paragraph>
    </div>
    <div v-else class="support-block">
      <Paragraph size="large" class="support-block__paragraph">
        Please try reloading the page. If the error persists please go to
        <a href="https://support.candlescience.com">
          support.candlescience.com
        </a>
      </Paragraph>
      <Button size="medium" :loading="reloading" @click="reload">
        Reload Page
      </Button>
    </div>
    <div class="tile-wrap">
      <nuxt-link class="error-tile candle" to="/">
        <SvgIcon icon="candle" class="tile-icon" />
        Candle Supplies
      </nuxt-link>
      <nuxt-link class="error-tile soap" to="/soap-making-supplies/">
        <SvgIcon icon="soap" class="tile-icon" />
        Soap Supplies
      </nuxt-link>
      <a
        class="error-tile faq"
        href="https://support.candlescience.com/hc/en-us"
      >
        <SvgIcon icon="help-circle" class="tile-icon" />
        FAQs
      </a>
      <nuxt-link class="error-tile learning" to="/learning/">
        <SvgIcon icon="bulb" class="tile-icon" />
        Learning
      </nuxt-link>
    </div>
  </Container>
</template>

<script>
  export default {
    props: { error: { type: Object, default: null } },
    data() {
      return {
        reloading: false,
      }
    },
    computed: {
      errorMessage() {
        const msg = this.error.message || this.error
        if (msg.match(/timeout/i)) {
          return "Timeout Error"
        }
        return msg
      },
      isNetworkError() {
        const msg = this.error.message || this.error
        return !!msg.match(/network/i)
      },
      isTimeoutError() {
        const msg = this.error.message || this.error
        return !!msg.match(/timeout/i)
      },
    },
    methods: {
      reload() {
        this.reloading = true
        window.location.reload()
        this.reloading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .error-headline {
    @include type("h1");
    margin: 0;
  }
  .error-subline {
    font-weight: normal;
    @include type("lead");
    margin-bottom: 3em;
  }
  .tile-wrap {
    display: flex;
    margin-right: $base-spacing * -4;

    flex-flow: row wrap;
    align-items: stretch;
  }
  .error-tile {
    height: $base-spacing * 40;
    padding: $base-spacing * 6;
    box-sizing: border-box;
    text-align: center;
    min-width: $base-spacing * 60;
    flex: 1;
    margin-right: $base-spacing * 4;
    margin-bottom: $base-spacing * 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @include type("h4");
    justify-content: center;
    border-radius: $border-radius;
    &:focus {
      outline: none;
    }
  }
  .tile-icon {
    opacity: 0.8;
    width: $base-spacing * 30;
    height: $base-spacing * 30;
    margin-bottom: $base-spacing * 4;
  }
  .candle {
    color: $red-700;
    background: $red-100;
    &:focus {
      box-shadow: 0 0 10px 2px $red-300;
    }
  }
  .soap {
    color: $green-700;
    background: $green-100;
    &:focus {
      box-shadow: 0 0 10px 2px $green-300;
    }
  }
  .faq {
    color: $yellow-700;
    background: $yellow-100;
    &:focus {
      box-shadow: 0 0 10px 2px $yellow-300;
    }
  }
  .learning {
    color: $blue-700;
    background: $blue-100;
    &:focus {
      box-shadow: 0 0 10px 2px $blue-300;
    }
  }

  .support-block {
    display: flex;
    padding: $space-m 0;
    grid-gap: $space-s;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .support-block__paragraph {
    margin: 0;
    margin-bottom: $space-m;
    @include type("lg");
    max-width: 40em;
  }
</style>
