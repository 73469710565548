export const generateRandomString = () => {
  const array = new Uint32Array(28) // this is of minimum required length for servers with PKCE-enabled
  window.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
    "",
  )
}

// Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
// btoa accepts chars only within ascii 0-255 and base64 encodes them.
// Then convert the base64 encoded to base64url encoded
//   (replace + with -, replace / with _, trim trailing =)
export const _base64UrlEncode = (str) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "")
}

export const _sha256 = (plain) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest("SHA-256", data)
}

export const encodeQuery = (queryObject) => {
  return Object.entries(queryObject)
    .filter(([, value]) => typeof value !== "undefined")
    .map(
      ([key, value]) =>
        encodeURIComponent(key) +
        (value != null ? "=" + encodeURIComponent(value) : ""),
    )
    .join("&")
}
