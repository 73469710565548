import { default as _91_46_46_46__93BtJK82pHiiMeta } from "/home/runner/work/Storefront/Storefront/pages/[..._].vue?macro=true";
import { default as _404iabxhpgtUBMeta } from "/home/runner/work/Storefront/Storefront/pages/404.vue?macro=true";
import { default as addXr3AGbutBKMeta } from "/home/runner/work/Storefront/Storefront/pages/account/addresses/add.vue?macro=true";
import { default as _91id_93qrjLPJ3lQFMeta } from "/home/runner/work/Storefront/Storefront/pages/account/addresses/edit/[id].vue?macro=true";
import { default as indexkH1q7H6SbBMeta } from "/home/runner/work/Storefront/Storefront/pages/account/addresses/index.vue?macro=true";
import { default as _91id_93UmDFFsX0z3Meta } from "/home/runner/work/Storefront/Storefront/pages/account/lists/[id].vue?macro=true";
import { default as indexeP5zh1YvO8Meta } from "/home/runner/work/Storefront/Storefront/pages/account/lists/index.vue?macro=true";
import { default as _91number_93TnpHJLlGiJMeta } from "/home/runner/work/Storefront/Storefront/pages/account/orders/[number].vue?macro=true";
import { default as indexnFNKGsTnYuMeta } from "/home/runner/work/Storefront/Storefront/pages/account/orders/index.vue?macro=true";
import { default as _91id_93el8IQCAIbIMeta } from "/home/runner/work/Storefront/Storefront/pages/account/reimbursement-requests/[id].vue?macro=true";
import { default as indexfh1VByC3ziMeta } from "/home/runner/work/Storefront/Storefront/pages/account/reimbursement-requests/index.vue?macro=true";
import { default as newp8VP1xEeeVMeta } from "/home/runner/work/Storefront/Storefront/pages/account/reimbursement-requests/new.vue?macro=true";
import { default as settingsYyLNeV53KDMeta } from "/home/runner/work/Storefront/Storefront/pages/account/settings.vue?macro=true";
import { default as _91id_93xVSw6yqURiMeta } from "/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/[id].vue?macro=true";
import { default as indexmcRrSzV9IXMeta } from "/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/index.vue?macro=true";
import { default as indexriKyhlvzoYMeta } from "/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/new/index.vue?macro=true";
import { default as RequestFormPkCz6Yc10WMeta } from "/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/RequestForm.vue?macro=true";
import { default as indexTm1ZzceMybMeta } from "/home/runner/work/Storefront/Storefront/pages/account/wallet/index.vue?macro=true";
import { default as accountGBoYzYw6slMeta } from "/home/runner/work/Storefront/Storefront/pages/account.vue?macro=true";
import { default as _91number_93W7X9bdvINBMeta } from "/home/runner/work/Storefront/Storefront/pages/cancel-order/[number].vue?macro=true";
import { default as indexlbu35iTuS2Meta } from "/home/runner/work/Storefront/Storefront/pages/cart/index.vue?macro=true";
import { default as _91_46_46_46state_93V0ecOORDhGMeta } from "/home/runner/work/Storefront/Storefront/pages/checkout/[...state].vue?macro=true";
import { default as addressHgeD2JNODsMeta } from "/home/runner/work/Storefront/Storefront/pages/checkout/address.vue?macro=true";
import { default as confirmqDLnmxeXKpMeta } from "/home/runner/work/Storefront/Storefront/pages/checkout/confirm.vue?macro=true";
import { default as deliverymHJOCaK2xtMeta } from "/home/runner/work/Storefront/Storefront/pages/checkout/delivery.vue?macro=true";
import { default as indexGsLdwoRlNoMeta } from "/home/runner/work/Storefront/Storefront/pages/checkout/index.vue?macro=true";
import { default as payment3f8lA7vrFXMeta } from "/home/runner/work/Storefront/Storefront/pages/checkout/payment.vue?macro=true";
import { default as indexplCbyiBEIWMeta } from "/home/runner/work/Storefront/Storefront/pages/clear-cookies/index.vue?macro=true";
import { default as _91number_930FHU0q8KgQMeta } from "/home/runner/work/Storefront/Storefront/pages/edit-order/[number].vue?macro=true";
import { default as indexO4DK1MlYTVMeta } from "/home/runner/work/Storefront/Storefront/pages/email_confirmation/index.vue?macro=true";
import { default as indexGiVEWxynU3Meta } from "/home/runner/work/Storefront/Storefront/pages/index.vue?macro=true";
import { default as indexGfWeGWzDkkMeta } from "/home/runner/work/Storefront/Storefront/pages/login/index.vue?macro=true";
import { default as edits8AZxpV2oxMeta } from "/home/runner/work/Storefront/Storefront/pages/password/edit.vue?macro=true";
import { default as recoverpvCSTZvNaVMeta } from "/home/runner/work/Storefront/Storefront/pages/password/recover.vue?macro=true";
import { default as _91taxon_93s77nzW0uxoMeta } from "/home/runner/work/Storefront/Storefront/pages/quick_order/[taxon].vue?macro=true";
import { default as indexK1fhpjYkvEMeta } from "/home/runner/work/Storefront/Storefront/pages/quick_order/index.vue?macro=true";
import { default as _91product_93ZLaylPw0ZkMeta } from "/home/runner/work/Storefront/Storefront/pages/reviews/new/[product].vue?macro=true";
import { default as index5pE055g1AaMeta } from "/home/runner/work/Storefront/Storefront/pages/search/index.vue?macro=true";
import { default as indexDxVDB7K0WYMeta } from "/home/runner/work/Storefront/Storefront/pages/signup/index.vue?macro=true";
import { default as _91provider_93heWj6CO7q5Meta } from "/home/runner/work/Storefront/Storefront/pages/social-callback/[provider].vue?macro=true";
import { default as indexdPPdAIT14dMeta } from "/home/runner/work/Storefront/Storefront/pages/top-selling-fragrance-oils-by-state/index.vue?macro=true";
export default [
  {
    name: "_",
    path: "/:_(.*)*",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/[..._].vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-add",
    path: "addresses/add",
    meta: addXr3AGbutBKMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/addresses/add.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-edit-id",
    path: "addresses/edit/:id()",
    meta: _91id_93qrjLPJ3lQFMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/addresses/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-addresses",
    path: "addresses",
    meta: indexkH1q7H6SbBMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lists-id",
    path: "lists/:id()",
    meta: _91id_93UmDFFsX0z3Meta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-lists",
    path: "lists",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/lists/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-number",
    path: "orders/:number()",
    meta: _91number_93TnpHJLlGiJMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/orders/[number].vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "orders",
    meta: indexnFNKGsTnYuMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reimbursement-requests-id",
    path: "reimbursement-requests/:id()",
    meta: _91id_93el8IQCAIbIMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/reimbursement-requests/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-reimbursement-requests",
    path: "reimbursement-requests",
    meta: indexfh1VByC3ziMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/reimbursement-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reimbursement-requests-new",
    path: "reimbursement-requests/new",
    meta: newp8VP1xEeeVMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/reimbursement-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "settings",
    meta: settingsYyLNeV53KDMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-tax_exemption_submissions-id",
    path: "tax_exemption_submissions/:id()",
    meta: _91id_93xVSw6yqURiMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-tax_exemption_submissions",
    path: "tax_exemption_submissions",
    meta: indexmcRrSzV9IXMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/index.vue").then(m => m.default || m)
  },
  {
    name: "account-tax_exemption_submissions-new",
    path: "tax_exemption_submissions/new",
    meta: indexriKyhlvzoYMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/new/index.vue").then(m => m.default || m)
  },
  {
    name: "account-tax_exemption_submissions-RequestForm",
    path: "tax_exemption_submissions/RequestForm",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/tax_exemption_submissions/RequestForm.vue").then(m => m.default || m)
  },
  {
    name: "account-wallet",
    path: "wallet",
    meta: indexTm1ZzceMybMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/account/wallet/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cancel-order-number",
    path: "/cancel-order/:number()",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/cancel-order/[number].vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-state",
    path: "/checkout/:state(.*)*",
    meta: _91_46_46_46state_93V0ecOORDhGMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/checkout/[...state].vue").then(m => m.default || m)
  },
  {
    name: "checkout-address",
    path: "/checkout/address",
    meta: addressHgeD2JNODsMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirm",
    path: "/checkout/confirm",
    meta: confirmqDLnmxeXKpMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/checkout/confirm.vue").then(m => m.default || m)
  },
  {
    name: "checkout-delivery",
    path: "/checkout/delivery",
    meta: deliverymHJOCaK2xtMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/checkout/delivery.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexGsLdwoRlNoMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment",
    path: "/checkout/payment",
    meta: payment3f8lA7vrFXMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: "clear-cookies",
    path: "/clear-cookies",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/clear-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "edit-order-number",
    path: "/edit-order/:number()",
    meta: _91number_930FHU0q8KgQMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/edit-order/[number].vue").then(m => m.default || m)
  },
  {
    name: "email_confirmation",
    path: "/email_confirmation",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/email_confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "password-edit",
    path: "/password/edit",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/password/edit.vue").then(m => m.default || m)
  },
  {
    name: "password-recover",
    path: "/password/recover",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/password/recover.vue").then(m => m.default || m)
  },
  {
    name: "quick_order-taxon",
    path: "/quick_order/:taxon()",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/quick_order/[taxon].vue").then(m => m.default || m)
  },
  {
    name: "quick_order",
    path: "/quick_order",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/quick_order/index.vue").then(m => m.default || m)
  },
  {
    name: "reviews-new-product",
    path: "/reviews/new/:product()",
    meta: _91product_93ZLaylPw0ZkMeta || {},
    component: () => import("/home/runner/work/Storefront/Storefront/pages/reviews/new/[product].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "social-callback-provider",
    path: "/social-callback/:provider()",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/social-callback/[provider].vue").then(m => m.default || m)
  },
  {
    name: "top-selling-fragrance-oils-by-state",
    path: "/top-selling-fragrance-oils-by-state",
    component: () => import("/home/runner/work/Storefront/Storefront/pages/top-selling-fragrance-oils-by-state/index.vue").then(m => m.default || m)
  }
]