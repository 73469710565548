import { useAuthStore } from "#imports"

const useAuth = () => {
  const { api } = useApi()
  const app = useNuxtApp()
  const authStore = useAuthStore()

  const setUser = (user: SpreeOrderUser) => {
    return authStore.setUser(user)
  }

  if (process.env.NODE_ENV !== "test") {
    app.hook("app:mounted", () => {
      api<CspreeApiSessionResponse>("/cs/api/session")
        .then((response) => {
          setUser(response.user)
        })
        .catch((err) => console.info(err))
    })
  }

  return {
    async loginWith(
      provider: "google" | "facebook" | "cookie",
      payload: CookieLoginPayload = { spree_user: {} },
    ): Promise<string | void> {
      switch (provider) {
        case "google":
          return await authStore.googleLogin()
        case "facebook":
          return await authStore.facebookLogin()
        case "cookie":
          return await authStore.cookieLogin(payload)
      }
    },
    async logout(): Promise<void> {
      return await authStore.logout()
    },
    setUser,
  }
}

export default useAuth
