<template>
  <p :class="['paragraph', `paragraph-size-${size}`]">
    <slot />
  </p>
</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: "medium",
        validator: (value) => {
          return ["small", "medium", "large"].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

  .paragraph {
    margin-top: 0;
    margin-bottom: $base-spacing * 2;
    @include type('lg');
    &-size-medium {
      @include type('lg');
    }
    &-size-small {
      @include type('sm');
    }
  }
</style>
