import Cookies from "js-cookie"
import * as utils from "../utils/auth/util"
import { useGtm } from "#imports"

export const useAuthStore = defineStore("auth", () => {
  const { api } = useApi()
  const afterLoginPath = ref("/")
  const user: Ref<SpreeOrderUser | null> = ref(null)
  const loggedIn = ref(false)

  const setAfterLoginPath = (path: string) => {
    const ignoredPaths = [
      "/login/",
      "/signup/",
      "/password/edit/",
      "/password/recover/",
      "/email_confirmation/",
      "/social-callback/google_oauth2/",
      "/social-callback/facebook/",
    ]
    if (!ignoredPaths.includes(path)) {
      afterLoginPath.value = path
    }
  }

  const setUser = (userPayload: SpreeOrderUser) => {
    user.value = userPayload

    useGtm()?.trackEvent({
      userId: userPayload.id,
      sumSalesSegment: userPayload.sum_sales_segment,
    })

    if (userPayload) {
      loggedIn.value = true
    }
  }

  const resetState = () => {
    user.value = null
    loggedIn.value = false
  }

  const facebookLogin = () => {
    const appId = import.meta.env.VITE_FB_OAUTH2_CLIENT_ID
    const redirectUrl = `${
      import.meta.env.VITE_BASE_URL
    }/social-callback/facebook/`

    const secret = utils.generateRandomString()
    Cookies.set("auth.code_verifier", secret)
    Cookies.set("auth.after_login_path", afterLoginPath.value)

    const url = `https://www.facebook.com/v3.3/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUrl}&state=${secret}&scope=email`
    window.location.replace(url)
  }

  const googleLogin = async () => {
    const googleEndpoint = "https://accounts.google.com/o/oauth2/auth"

    const randomString = utils.generateRandomString()
    const secret = utils._base64UrlEncode(await utils._sha256(randomString))

    Cookies.set("auth.code_verifier", secret)
    Cookies.set("auth.after_login_path", afterLoginPath.value)

    const opts = {
      protocol: "oauth2",
      response_type: "code",
      client_id: import.meta.env.VITE_GOOGLE_OAUTH2_CLIENT_ID,
      redirect_uri: `${
        import.meta.env.VITE_BASE_URL
      }/social-callback/google_oauth2/`,
      scope: "email",
      code_challenge: secret,
    }

    const url = googleEndpoint + "?" + utils.encodeQuery(opts)
    window.location.replace(url)
  }

  const cookieLogin = async (payload: CookieLoginPayload) => {
    try {
      const userRes = await api<CspreeApiSessionResponse>("/cs/api/session", {
        method: "POST",
        body: payload,
      })

      setUser(userRes?.user)

      return Promise.resolve(userRes.message)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const logout = async () => {
    await api("/cs/api/session", {
      method: "DELETE",
    }).then(() => {
      resetState()
    })
  }

  const getSession = async () => {
    try {
      const data = await api<CspreeApiSessionResponse>("/cs/api/session")
      setUser(data.user)
    } catch (error) {
      resetState()
    }
  }

  return {
    cookieLogin,
    getSession,
    logout,
    googleLogin,
    facebookLogin,
    resetState,
    setUser,
    user,
    setAfterLoginPath,
    loggedIn,
    afterLoginPath,
  }
})
