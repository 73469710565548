import bugsnag from "@bugsnag/js"
import BugsnagVue from "@bugsnag/plugin-vue"

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig()
  if (config.public.BUGSNAG_API_KEY) {
    bugsnag.start({
      apiKey: config.public.BUGSNAG_API_KEY,
      enabledReleaseStages: ["production"],
      plugins: [new BugsnagVue()],
    })
    const bugsnagVue = bugsnag.getPlugin("vue")
    vueApp.use(bugsnagVue)
  }
})
