<template>
  <component
    :is="`h${level}`"
    :class="[
      'headline',
      size != undefined ? SIZE_CLASSES[size] : SIZE_CLASSES[level],
      `headline-alignment-${alignment}`,
      noMargin && 'no-margin',
      subline && 'headline-has-subline',
    ]"
  >
    <span class="headline-text">
      <slot />
    </span>
    <span
      v-if="subline"
      :class="[
        'subline',
        `subline-font-${sublineFont}`,
        `subline-color-${sublineColor}`,
      ]"
    >
      {{ subline }}
    </span>
  </component>
</template>

<script>
  const SIZE_CLASSES = {
    0: "size-zero",
    1: "size-one",
    2: "size-two",
    3: "size-three",
    4: "size-four",
    5: "size-five",
    6: "size-six",
  }

  function sizeLevelValidator(value) {
    return Object.keys(SIZE_CLASSES).includes(value.toString())
  }

  export default {
    name: 'Headline',
    props: {
      level: {
        type: [String, Number],
        default: 1,
        validator: sizeLevelValidator
      },
      size: {
        type: [String, Number],
        default: null,
        validator: sizeLevelValidator
      },
      alignment: {
        type: String,
        default: "left",
        validator: (value) => {
          return ["center", "left", "right"].includes(value)
        },
      },
      noMargin: {
        type: Boolean,
        default: false,
      },
      subline: {
        type: String,
        default: null,
      },
      sublineColor: {
        type: String,
        default: "gray",
        validator: (value) => {
          return ["gray", "green", "red", "blue", "purple"].includes(value)
        },
      },
      sublineFont: {
        type: String,
        default: "brand",
        validator: (value) => {
          return ["brand", "serif"].includes(value)
        },
      },
    },
    data() {
      return {
        SIZE_CLASSES,
      }
    },
  }
</script>

<style lang="scss" scoped>

  .headline {
    margin-top: 0;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  .headline-alignment {
    &-center {
      align-items: center;
      text-align: center;
    }
    &-left {
      align-items: flex-start;
      text-align: left;
    }
    &-right {
      align-items: flex-end;
      text-align: right;
    }
  }
  .size-zero {
    .headline-text {
      @include type('display');
    }
    .subline {
      @include type('h2');
      font-weight: normal;
      margin-top: .25em;
    }
  }
  .size-one {
    .headline-text {
      @include type('h1');
    }
    .subline {
      @include type('h3');
      font-weight: normal;
      margin-top: .125em;
    }
  }
  .size-two {
    .headline-text {
      @include type('h2');
    }
    .subline {
      @include type('lg');
    }
  }
  .size-three {
    .headline-text {
      @include type('h3');
    }
    .subline {
      @include type('lg');
    }
  }
  .size-four {
    .headline-text {
      @include type('h4');
    }
    .subline {
      @include type('md');
    }
  }
  .size-five {
    .headline-text {
      @include type('h5');
    }
    .subline {
      @include type('sm');
    }
  }
  .size-six {
    .headline-text {
      @include type('h6');
    }
    .subline {
      @include type('sm');
    }
  }

  .subline {
    max-width: $base-spacing * 180;
    @include type('md');
    font-weight: normal;

    // &-font-serif {
    //   font-style: italic;
    //   font-family: $font-family-serif;
    // }
  }

  .subline-color {
    &-gray {
      color: $gray-800;
      .cs-dark & {
        color: rgba(white, 0.8);
      }
    }
    &-red {
      color: $red-text;
      .cs-dark & {
        color: $red;
      }
    }
    &-blue {
      color: $blue-text;
      .cs-dark & {
        color: $blue;
      }
    }
    &-green {
      color: $green-text;
      .cs-dark & {
        color: $green-500;
      }
    }
    &-purple {
      color: $purple-text;
      .cs-dark & {
        color: $purple-500;
      }
    }
  }
</style>
